import "../sass/editor.scss";

import MediumEditor from "medium-editor";

import { initUserMentionsForElement } from "./components/UserMentions";
import initUGCTemplates from "./components/UGCTemplates";
import { sendBrowserAgnosticEvent } from "./core/utils";

export function initEditor(props) {
  const {
    editor: {
      customButtons,
      disabled: editorDisabled,
      inputId,
      inputContainerSelector,
      templates,
      templatesContainerSelector,
    },
    userMention,
  } = props;

  let alwaysShowTemplates = false;
  if (props.editor.alwaysShowTemplates) {
    alwaysShowTemplates = true;
  }

  // Rich text editing
  let editor;
  if (inputId && !editorDisabled) {
    const input = document.getElementById(inputId);
    const elementsContainer = input.closest(inputContainerSelector);

    editor = new MediumEditor([input], {
      elementsContainer,
      extensions: {
        imageDragging: {},
      },
      imageDragging: false,
      placeholder: {
        text: input.getAttribute("placeholder"),
        hideOnClick: false,
      },
      toolbar: {
        align: "center",
        buttons: ["bold", "italic", "underline", "orderedlist", "unorderedlist"].concat(
          customButtons || [],
        ),
        diffTop: 80,
        static: true,
        sticky: true,
        stickyTopOffset: 20,
        updateOnEmptySelection: true,
      },
    });

    if (userMention) {
      const contentEditable = input.parentElement.querySelector(
        '[contenteditable="true"]',
      );

      initUserMentionsForElement(
        contentEditable,
        userMention.memorialSlug,
        userMention.userSearchEndpoint,
      );
    }

    // Template handling
    if (templates && templatesContainerSelector) {
      const templatesContainer = document.querySelector(templatesContainerSelector);
      initUGCTemplates(
        templatesContainer,
        input,
        templates,
        () =>
          templatesContainer &&
          templatesContainer.classList.contains("d-none") !== true,
        false,
        editor
          ? (text, resetToPrevious) => {
              if (editor.getContent().length) {
                if (
                  window.confirm(
                    "Are you sure? Switching to a different template will delete any edits that you've made.",
                  )
                ) {
                  editor.setContent(text, 0);
                } else if (resetToPrevious) {
                  resetToPrevious();
                }
              } else {
                editor.setContent(text, 0);
              }
            }
          : null,
        true,
        alwaysShowTemplates,
      );
    }

    editor.subscribe("editableInput", () => {
      sendBrowserAgnosticEvent(input, "input");
    });

    input.addEventListener("el-editor-external-input", () => {
      editor.setContent(input.value, 0);
    });
  }
}

/**
 * For a given MediumEditor element, replace one match of `pattern` with `replacement`.
 *
 * `pattern` and `replacement` are passed to String.prototype.replace() so check the
 * docs for that function for details.
 *
 * @param {Element} editorElement - DOM Node with a MediumEditor instance
 * @param {string | RegExp} pattern - the pattern
 * @param {string} replacement - the replacement string
 */
export function replaceContent(editorElement, pattern, replacement) {
  const richEditor = MediumEditor.getEditorFromElement(editorElement);
  richEditor.setContent(richEditor.getContent().replace(pattern, replacement));
}
